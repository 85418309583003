.main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 4rem;
}

.gretting-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
}

.greeting-main {
  display: flex;
}

.greeting-main > * {
  flex: 1;
  margin-bottom: 30px;
}
.button-greeting-div{
  display: flex;
  margin-top: 20px;
}

.greeting-text {
  font-size: 70px;
  line-height: 1.1;
  margin-bottom: 0;
}

.greeting-text-p {
  font-size: 30px;
  line-height: 40px;
}

.greeting-image-div > img {
  max-width: 100%;
  height: auto;
}

.subname {
  display: inline-block;
  font-size: 25px;
  padding-left: 20px;
  color: #888;
  margin-top: 0;
}

/* Media Query */
@media (max-width: 1380px) {
  .greeting-text {
    font-size: 50px;
  }
  .greeting-text-p {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .button-greeting-div{
    justify-content: space-around;
  }
  .greeting-text {
    font-size: 30px;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }

  .subname {
    font-size: 13px;
    display: block;
    padding-left: 0;
    color: #888;
    text-align: center;
  }

  .greeting-main {
    display: block;
  }
}
