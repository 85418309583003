.contact-title {
  font-size: 56px;
  font-weight: 400;
  text-align: center;
}
.contact-detail,
.contact-detail-email {
  font-size: 40px;
  font-weight: 400;
  color: #868e96;
}

.contact-subtitle {
  text-transform: uppercase;
  text-align: center;
}

.contact-text-div {
  text-align: center;
  margin-top: 1.5rem;
}

.contact-margin-top {
  margin-top: 4rem;
}

/* Media Query */
@media (max-width: 1380px) {
  .contact-title {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .contact-title {
    font-size: 30px;
    text-align: center;
  }
  .contact-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }
  .contact-detail,
  .contact-detail-email {
    font-size: 20px;
  }
}
